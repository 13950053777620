@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url("https://fonts.googleapis.com/css?family=Oswald:300,400,700,900");
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Roboto Condensed";
  src: url("theme/font/RobotoCondensed-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Condensed Bold";
  src: url("theme/font/RobotoCondensed-Bold.ttf") format("truetype");
  font-weight: bold;
}

/* From https://github.com/googlefonts/robotoslab/tree/main/fonts/ttf */
@font-face {
  font-family: "Roboto Slab";
  src: url("theme/font/RobotoSlab-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Knockout";
  src: url("theme/font/Knockout-50.otf") format("truetype");
}

body {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

html,
body {
  height: 100%;
  font-size: 16px;
}

legend {
  font-weight: bold;
}

/* Common colours for headings etc. */

p,
ul,
ol {
  font-family: "Roboto", sans-serif;
  color: #242424;
}

a:link,
a:visited {
  color: #e2231b;
}
a:link p,
a:visited p {
  color: #e2231b;
}
a.btn:link,
a.btn:visited {
  border: 2px solid white;
  color: white;
  padding: 6px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  max-width: initial !important;
}

/* Common heading spacing */
h3,
h4 {
  margin-bottom: 0.5rem;
  margin-bottom: 0.75rem;
}

/* Using a dark themed website - make the scrollbars dark */
/* Based on - https://www.w3schools.com/howto/howto_css_custom_scrollbar.asp */
/* no-touch is added by modernizr */
.no-touch .horizontal-swim-lane ::-webkit-scrollbar {
  height: 0px;
  width: 3px;
}

.no-touch .horizontal-swim-lane :hover::-webkit-scrollbar {
  display: none;
}

/* Track */
.no-touch ::-webkit-scrollbar-track {
  background: black;
}

/* Handle */
.no-touch ::-webkit-scrollbar-thumb {
  background: #737373;
}

/* Handle on hover */
.no-touch ::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
}

.changes-in-progess-overlay {
  position: fixed;
  /* Sit on top of the page content */
  display: block;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 1301;
  /* Specify a stack order in case you're using a different order for other elements */
  overflow-x: hidden;
  overflow-y: hidden;
}

.changes-in-progess-overlay .overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 5rem;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 5px solid #4d4d4d;
  /* Light grey */
  border-top: 5px solid #bfbfbf;
  /* Blue */
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  animation: spin 2s linear infinite;
}

img.discipline-tile-image {
  max-width: unset;
}
.discipline-tiles-row div, .discipline-tiles-row div:before, .discipline-tiles-row div:after {
  box-sizing: initial !important;
}

/* Override design system styles, which screw up the scrolling. */
body, html {
  scroll-behavior: initial !important;
  scroll-snap-type: initial !important;
  scroll-padding-top: initial  !important;
}
@media (min-width: 1024px) {
  html {
    scroll-snap-type: initial !important;
    scroll-padding-top: initial  !important;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
